@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?scgqpa');
  src:  url('icomoon.eot?scgqpa#iefix') format('embedded-opentype'),
    url('icomoon.ttf?scgqpa') format('truetype'),
    url('icomoon.woff?scgqpa') format('woff'),
    url('icomoon.svg?scgqpa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-back:before {
  content: "\e918";
}
.ico-bars:before {
  content: "\e91b";
}
.ico-th:before {
  content: "\e91c";
}
.ico-shopping-bag:before {
  content: "\e919";
}
.ico-user-alt:before {
  content: "\e91a";
}
.ico-check-circle:before {
  content: "\e917";
}
.ico-trash:before {
  content: "\e916";
}
.ico-check:before {
  content: "\e915";
}
.ico-user:before {
  content: "\e900";
}
.ico-tow-truck-alt:before {
  content: "\e901";
}
.ico-tow-truck:before {
  content: "\e902";
}
.ico-play-button:before {
  content: "\e903";
}
.ico-placeholder:before {
  content: "\e904";
}
.ico-phone-call:before {
  content: "\e905";
}
.ico-painting:before {
  content: "\e906";
}
.ico-next:before {
  content: "\e907";
}
.ico-motorcycle:before {
  content: "\e908";
}
.ico-mechanic:before {
  content: "\e909";
}
.ico-map:before {
  content: "\e90a";
}
.ico-comments-alt:before {
  content: "\e90b";
}
.ico-clock-alt:before {
  content: "\e90c";
}
.ico-clock:before {
  content: "\e90d";
}
.ico-car-wash:before {
  content: "\e90e";
}
.ico-car-alt:before {
  content: "\e90f";
}
.ico-car:before {
  content: "\e910";
}
.ico-call:before {
  content: "\e911";
}
.ico-breakdown:before {
  content: "\e912";
}
.ico-accident:before {
  content: "\e913";
}
.ico-email:before {
  content: "\e914";
}
